import React, { useEffect, useState } from "react";
import logo from "./SmakonecupLogo.png";
import bgDekstop from "./background_coming_soon.png";
import bgMobile from "./background_coming_soon_mobile.png";
import './App.css';

function formatNumber(num) {
  return num.toString().padStart(2, "0");
}

function App() {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const targetDate = new Date("October 22, 2024").getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeGap = targetDate - now;

      if (timeGap > 0) {
        const days = Math.floor(timeGap / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeGap % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeGap % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeGap % (1000 * 60)) / 1000);

        setCountdown({ days, hours, minutes, seconds });
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  
  return (
    <div className="App">
      <div>
      <div className='bg-[url("background_coming_soon_mobile.png")] lg:bg-[url("background_coming_soon.png")] bg-cover h-screen w-screen bg-center absolute lg:overflow-auto overflow-y-auto overflow-x-hidden'>

        {/*ComingSoon*/}
        <div className='relative select-none font-clashdisplay text-white opacity-10 text-center rotate-90 lg:rotate-0 translate-y-[350px] lg:translate-y-[200px] text-[12rem] leading-[5.8rem] lg:leading-[2rem] lg:z-10 lg:grid grid-row-2 lg:text-[15rem] min-[376px]:text-[11rem]'>
          <span className='relative flex justify-center pb-4 lg:flex justify-self-start lg:mb-10 lg:pb-10'>coming</span>
          <span className='relative flex justify-center pt-4 lg:flex justify-self-end lg:mt-10 lg:pt-5'>soon</span>
        </div>
        

        {/*RoadTo*/}
        <div className='lg:-mt-[0px] -mt-[80px]'>
        <div className='flex justify-center'>
          <div className='text-white text-center font-normal mb-10 lg:mb-0 font-britney text-[40px] lg:text-[75px] tracking-wide'>Road to <br className='lg:hidden'/>SOC 2024</div>
        </div>

        {/* Duplicate ComingSoon */}
        <div className='relative select-none font-clashdisplay text-white opacity-10 text-center hidden translate-y-[350px] lg:translate-y-[200px] lg:mt-[20px] text-[12rem] leading-[5.8rem] lg:leading-[2rem] lg:z-10 lg:grid grid-row-2 lg:text-[15rem] min-[376px]:text-[11rem]'>
          <span className='relative flex justify-center pb-4 lg:flex justify-self-start lg:mb-10 lg:pb-10'>coming</span>
          <span className='relative flex justify-center pt-4 lg:flex justify-self-end lg:mt-10 lg:pt-5'>soon</span>
        </div>

        {/* clock */}
        <div className='lg:flex justify-evenly mt-[1rem] lg:-mt-[150px] min-[376px]:mt-[1rem]'>
          <div className='lg:grid grid-cols-2 lg:py-0 lg:px-10'>
            <div className='flex justify-center items-center z-10 grid gap-7 lg:gap-3 grid-cols-2 mx-12 lg:mx-[10px] pb-16 font-manrope text-white text-center font-bold leading-7
                            lg:grid gap-0 lg:pb-0 mx-0'>
                <span className='text-[4rem] lg:text-[8rem] lg:pr-5'>{formatNumber(countdown.days)} <br/> <span className="text-base font-bold text-[20px] lg:text-[27px] lg:pt-2">Days</span></span>
                <span className='text-[4rem] lg:text-[8rem] lg:px-5'>{formatNumber(countdown.hours)} <br/> <span className="text-base font-bold text-[20px] lg:text-[27px] lg:pt-2">Hours</span></span>
            </div>
            <div className='flex justify-center items-center z-10 grid gap-7 lg:gap-3 grid-cols-2 mx-12 lg:mx-[10px] pb-12 font-manrope text-white text-center font-bold leading-7
                            lg:grid gap-0 lg:pb-0 mx-0'>
                <span className='text-[4rem] lg:text-[8rem]'>{formatNumber(countdown.minutes)} <br/> <span className="text-base font-bold text-[20px] lg:text-[27px] lg:pt-2">Minutes</span></span>
                <span className='text-[4rem] lg:text-[8rem]'>{formatNumber(countdown.seconds)} <br/> <span className="text-base font-bold text-[20px] lg:text-[27px] lg:pt-2">Seconds</span></span>
            </div>
          </div>
        </div>

        {/* Register Now */}
        <div className='flex justify-center mt-2 lg:mt-20'>
          {/*<a href='https://drive.google.com/drive/folders/1qe0OyVc7c16VZmvLeEf5sxXFSY4daOLy' target="blank" className='bg-yellow-600 py-[12px] px-[20px] mx-24 font-manrope font-bold justify-center flex text-base text-xl lg:text-2xl
          border-black border-[1px] hover:bg-yellow-100 focus:bg-yellow-100 transition duration-300 ease-in-out z-10'>REGISTER NOW</a>*/}

          <a href='https://socregis.smakone.org' target="blank" className='bg-yellow-600 py-[12px] px-[20px] mx-24 font-manrope font-bold justify-center flex text-base text-xl lg:text-2xl
          border-black border-[1px] hover:bg-yellow-100 focus:bg-yellow-100 transition duration-300 ease-in-out z-10'>REGISTER NOW</a>
        </div>

        {/* bySOC */}
        <div className='flex justify-center'>
          <div className="font-manrope font-bold text-white mt-16 inline-flex justify-center">
            <span className='text-xl lg:text-2xl mt-2 lg:mt-3'>by</span> 
            <span><img src={logo} alt='' className='w-44 lg:w-52'/></span>
          </div>
        </div>
      </div>
      </div>
      </div>
      
      
    </div>
  );
}

export default App;
